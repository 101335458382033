export const MessageType = {
  BUY_COMPLETED: "BuyComplete",
  BUY_REMINDER: "BuyReminder",
  CURBSIDE_SERVICE: "CurbsideService",
  CURBSIDE_BUY_CHECK_IN: "CurbsideBuyCheck-in",
  STORE_INFORMATION: "StoreInformation",
  OTHER: "Other",
};

export const MessageTypeLabel = {
  BUY_COMPLETED: "Buy completed",
  BUY_REMINDER: "Buy reminder",
  CURBSIDE_SERVICE: "Curbside service",
  CURBSIDE_BUY_CHECK_IN: "Curbside buy check-in",
  STORE_INFORMATION: "Store information",
  OTHER: "Other",
};
