<template>
  <MessageForm :auto-reply="autoReply" :message="message" @submit="onSubmit">
    <template #actions>
      <VxBtn type="button" color="error" text @click="onDeleteClick">
        Delete message
      </VxBtn>
    </template>
  </MessageForm>
</template>

<script>
import get from "lodash/get";
import findIndex from "lodash/findIndex";
import { VxBtn } from "@/core-ui";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import MessageForm from "./components/MessageForm/MessageForm.vue";
import {
  STORE_MESSAGE_QUERY,
  STORE_MESSAGES_QUERY,
  STORE_MESSAGE_UPDATE,
  STORE_MESSAGE_DELETE,
} from "./graphql";

export default {
  name: "EditMessageView",
  components: { VxBtn, MessageForm },
  mixins: [hasSnackbarAccess],
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    autoReply: {
      type: Boolean,
      default: false,
    },
    messageId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    message: { id: 0, title: "" },
  }),
  apollo: {
    message: {
      query: STORE_MESSAGE_QUERY,
      variables() {
        return { id: this.messageId, storeId: this.storeId };
      },
      update: (data) => data.storeMessage,
    },
  },
  methods: {
    async onSubmit(form) {
      const result = await this.$apollo.mutate({
        mutation: STORE_MESSAGE_UPDATE,
        variables: {
          input: {
            id: this.messageId,
            ...form.values,
          },
        },
      });

      const { storeMessage, errors } = result.data.storeMessageUpdate;

      if (errors.length > 0) {
        this.showSnackbar({
          text: "Error has happened while saving prewritten text message",
        });
      } else {
        this.showSnackbar({
          text: `Update ${storeMessage.title} message successfully`,
          color: "success",
        });

        this.updateCachedQuery(this.$apollo.getClient().cache, result);
      }

      form.resolve();

      this.$router.back();
    },
    /**
     * Callback for updateMutation
     * @callback
     * @param {Object} cache - Apollo cache store
     * @param {Object} mutationResult - Mutation result
     */
    updateCachedQuery(cache, mutationResult) {
      // Read the cache. Update the message in the cache or push it to the cache
      const updatedMessage = get(
        mutationResult,
        "data.storeMessageUpdate.storeMessage"
      );
      const { messages = [] } = cache.readQuery({
        query: STORE_MESSAGES_QUERY,
        variables: { storeId: this.storeId },
      });
      const updatedMessages = this.mergeOrAddMessage(messages, updatedMessage);
      cache.writeQuery({
        query: STORE_MESSAGES_QUERY,
        variables: { storeId: this.storeId },
        data: { messages: updatedMessages },
      });
    },
    /**
     * Takes a list of messages. Updates the message in place if it exists OR
     * adds it to the list.
     * @param {Object[]} messages - List of messages from cache.ReadQuery
     * @param {Object} message - Updated message result to be merged or added
     * @returns {Object[]}
     */
    mergeOrAddMessage(messages, message) {
      const index = findIndex(messages, { id: message.id });
      // based on whether a message exists...
      index > -1
        ? // Update...
          (messages[index] = { ...messages[index], ...message })
        : // Or push.
          messages.push(message);

      return messages;
    },

    async onDeleteClick() {
      await this.$apollo.mutate({
        mutation: STORE_MESSAGE_DELETE,
        variables: {
          id: this.message.id,
        },
      });

      this.showSnackbar({
        text: `Deleted ${this.message.title} message successfully`,
        color: "success",
      });

      const cache = this.$apollo.getClient().cache;
      const { messages = [] } = cache.readQuery({
        query: STORE_MESSAGES_QUERY,
        variables: { storeId: this.storeId },
      });

      cache.writeQuery({
        query: STORE_MESSAGES_QUERY,
        variables: { storeId: this.storeId },
        data: { messages: messages.filter((x) => x.id !== this.message.id) },
      });

      this.$router.back();
    },
  },
};
</script>
