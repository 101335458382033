<template>
  <VxForm ref="form" v-slot="{ submitting }" v-on="$listeners">
    <VxFormGroup :title="titleLabel">
      <v-row>
        <v-col cols="12" md="5">
          <VxTextField
            v-model="form.title"
            :label="titleLabel"
            name="title"
            placeholder="Add a title here"
            rules="required"
            outlined
          />
        </v-col>
      </v-row>
    </VxFormGroup>
    <VxFormGroup :title="messageLabel">
      <VxTextarea
        v-model="form.value"
        :label="messageLabel"
        name="message"
        placeholder="Add your message here"
        rules="required|max:160"
        :counter="160"
        outlined
      />
    </VxFormGroup>
    <VxFormGroup title="Message type">
      <v-row>
        <v-col cols="12" md="5">
          <VxSelect
            v-model="form.intentType"
            label="Message type"
            name="intentType"
            placeholder="Select type"
            :items="messageTypes"
            item-text="label"
            item-value="enumVal"
            rules="required"
            outlined
            clearable
          />
        </v-col>
      </v-row>
    </VxFormGroup>
    <VxFormActions>
      <slot name="actions"></slot>
      <VxBtn type="submit" :loading="submitting">Save</VxBtn>
    </VxFormActions>
  </VxForm>
</template>

<script>
import { MessageType, MessageTypeLabel } from "../../constants";
import { useFormObject } from "@/mixins/useFormObject";
import {
  VxForm,
  VxFormGroup,
  VxFormActions,
  VxTextField,
  VxTextarea,
  VxSelect,
  VxBtn,
} from "@/core-ui";

export default {
  name: "MessageForm",
  components: {
    VxForm,
    VxFormGroup,
    VxFormActions,
    VxTextField,
    VxTextarea,
    VxSelect,
    VxBtn,
  },
  mixins: [
    useFormObject({
      prop: "message",
      default: {
        id: 0,
        title: "",
        value: "",
        intentType: "",
        isAutoReply: null,
      },
    }),
  ],
  props: {
    autoReply: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    messageTypes: Object.keys(MessageType).map((key) => ({
      enumVal: MessageType[key],
      label: MessageTypeLabel[key],
    })),
  }),
  computed: {
    isAutoReply() {
      return this.message.isAutoReply === true || this.autoReply === true;
    },
    titleLabel() {
      return this.isAutoReply ? "Shortcode" : "Template name";
    },
    messageLabel() {
      return this.isAutoReply ? "Automatic reply" : "Message text";
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
